import { makeObservable, observable, action } from 'mobx';

import { localStorage, initEnv } from '@zxhj/component-library';

const { getLocalStorage } = localStorage;
const { env } = initEnv;

class Global {
  permissionValues = JSON.parse(getLocalStorage(`${env  }ZXHJ-permissionValues`));

  constructor() {
    makeObservable(this, {
      permissionValues: observable,
      initData: action.bound,
    });
  }

  initData(dataName, data) {
    this[dataName] = data;
  }
}

export default Global;
