import { appState } from '@zxhj/component-library';

import Stock from './stock';
import AddSold from './addSold';
import CentralWareStock from './centralWareStock';
import StockOperationDetails from './stockOperationDetails';
import UnOnlineList from './unOnlineList';

class Store {
  appState = appState;

  // 库存
  stockModel = new Stock({ appState: this.appState, baseUrl: 'zl-central-warehouse' });

  // 上架商品
  addSoldCentralModel = new AddSold({ appState: this.appState });

  // 存在库存未上架的商品
  unCentralOnlineListModel = new UnOnlineList({
    appState: this.appState,
    baseUrl: '/zl-central-warehouse/getUnOnlineReallist',
  });

  centralWareStockModel = new CentralWareStock({ appState: this.appState });

  stockOperationDetailsModel = new StockOperationDetails({ appState: this.appState });
}

export default Store;
