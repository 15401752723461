import { action, observable, computed, makeObservable, toJS, runInAction } from 'mobx';

import BasicModel from '../basicModel';

export default class AddSold extends BasicModel {
  initRequestData = {
    pageSize: 10,
    pageNum: 1,
  };

  purchaseQuote = []; // 报价提交参数

  constructor(props) {
    super({ ...props, baseUrl: 'spu' });
    this.initRequestData = {
      pageSize: 10,
      pageNum: 1,
      tradeType: props.tradeType,
    };
    this.requestData = {
      ...this.initRequestData,
    };
    makeObservable(this, {});
  }

  async updateExpandDetail(id) {
    const { skuList } = await this.appState.fetch(`/${this.baseUrl}/${id}`, {
      method: 'GET',
    });

    runInAction(() => {
      this.allExpandData[id] = skuList;
    });
  }

  clearExpandDetail(id) {
    delete this.allExpandData[String(id)];
  }

  // 商品上下架
  async spuOnline(id, onlineStatus) {
    await this.appState.fetch(`/spu/onlineSpu/${id}`, {
      method: 'POST',
      formData: { onlineStatus },
    });
  }

  // 商品规格上下架
  async skuOnline(id, onlineStatus) {
    await this.appState.fetch(`/sku/onlineSku/${id}`, {
      method: 'POST',
      formData: { onlineStatus },
    });
  }
}
