import { appState } from '@zxhj/component-library';

import StandardGoodsModel from './standardGoods';
import Category from './category';
import Brand from './brand';
import RepertorySpu from './repertorySpu';

class Store {
  appState = appState;

  repertorySpuModel = new RepertorySpu({ appState: this.appState });

  standardGoodsModel = new StandardGoodsModel({ appState: this.appState });

  categoryModel = new Category({ appState: this.appState });

  brandModel = new Brand({ appState: this.appState });
}

export default Store;
