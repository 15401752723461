import { appState } from '@zxhj/component-library';

import Purchase from './purchase';
import WillQuotation from './willQuotation';

class Store {
  appState = appState;

  purchaseModel = new Purchase({ appState: this.appState });

  willQuotationModel = new WillQuotation({ appState: this.appState });
}

export default Store;
