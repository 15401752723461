import { action, observable, computed, makeObservable, toJS, runInAction } from 'mobx';
import { leadInKeys } from '@/utils/utils';
import { shipments } from '../schema/purchase';
import BasicModel from '../basicModel';

export default class Purchase extends BasicModel {
  deliveryVisible = false;

  deliveryData = {}; // 提交更新发货数据

  deliverModalMode = '';

  selectedRowKeys = [];

  selectedRows = {};

  enableDeliveryList = []; // 可发发货的数据

  disableDeliveryList = []; // 不符合批量发货条件

  shipmentsRecords = {}; // 收货信息

  relenishList = {};

  constructor(props) {
    super({ ...props, baseUrl: 'saaspurchase' });
    makeObservable(this, {
      selectedRows: observable,
      enableDeliveryList: observable,
      deliverModalMode: observable,
      deliveryVisible: observable,
      deliveryData: observable,
      shipmentsRecords: observable,
      selectedRowKeys: observable,
      relenishList: observable,
      getPurchaseShipments: action.bound,
      updateDeliveryData: action.bound,
      putPurchase: action.bound,
      batchDelivery: action.bound,
      finishSaaspurchase: action.bound,
      closeDelivery: action.bound,
      openDelivery: action.bound,
      batchExport: action.bound,
      cancelPurchase: action.bound,
      onReplyList: action.bound,
      changePurchase: action.bound,
    });
  }

  async closeDelivery() {
    this.deliveryVisible = false;
  }

  async openDelivery(type) {
    this.deliverModalMode = type;
    this.deliveryVisible = true;
  }

  async batchExport(data) {
    const result = await this.appState.fetch(`/${this.baseUrl}/batchExport`, {
      body: data,
    });
    // console.log(result, 'result')
  }

  // 批量订单发货
  async batchDelivery(data) {
    await this.appState.fetch('/purchase-shipments/addList', {
      method: 'PUT',
      body: data,
    });
  }

  // 查询发货记录
  async getPurchaseShipments(id) {
    const records = await this.appState.fetch(`/purchase-shipments/${id}/getReceiptAndDeliveryInfor`, {
      method: 'GET',
    });
    this.shipmentsRecords = records;
  }

  // 单个订单发货
  async putPurchase() {
    await this.appState.fetch('/purchase-shipments', {
      method: 'PUT',
      body: this.deliveryData,
    });
  }

  async updateDeliveryData(data) {
    this.deliveryData = data;
  }

  // 发货完成
  async finishSaaspurchase(id) {
    await this.appState.fetch(`/saaspurchase/${id}`, {
      method: 'PATCH',
    });
  }

  // 取消订单
  async cancelPurchase(id) {
    await this.appState.fetch(`/saaspurchase/cancel/${id}`, {
      method: 'PATCH',
    });
  }

  async onReplyList(params, callback) {
    const data = await this.appState.fetch(`/jc-supplier/pageList`, {
      method: 'POST',
      body: {
        pageSize: 10,
        ...params,
      },
    });
    runInAction(() => {
      if (callback) {
        callback(data);
      } else {
        this.relenishList = data;
      }
    });
  }

  async changePurchase(purchaseParams) {
    if (JSON.stringify(purchaseParams) !== '{}') {
      purchaseParams = {
        newAddressId: purchaseParams.newAddressId || this.modalData.addressId,
        newNum: purchaseParams.newNum || this.modalData.num,
        newSkuId: purchaseParams.newSkuId || this.modalData.skuId,
        purchaseId: this.modalData.id,
        remark: purchaseParams.remark || this.modalData.remark,
      };
      await this.appState.fetch(`/saaspurchase/changePurchase`, {
        method: 'PATCH',
        body: purchaseParams,
      });
    }
  }
}
