const spuSchema = [
  // "allPic",
  'allPicList',
  'brandId',
  'budgetMatchFieldId',
  'categoryId',
  'code',
  'mainPic',
  'skuList',
  'title',
  'type',
  'unitId',
  'tradeType',
  'detailPic',
  'warehouseType',
  'isItDecimal',
];

const skuSchema = [
  'id',
  'code',
  'price',
  'spec',
  'specRatio',
  'specUnit',
  'sort',
  'centralWarehouseStock',
  'skuIsOnline',
  // "spuId",
];

export { spuSchema, skuSchema };
