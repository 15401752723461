import React, { Suspense, useMemo, useRef } from 'react';

import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { localStorage, initEnv } from '@zxhj/component-library';

import { toJS } from 'mobx';
import { useStore } from '@/model/store';
import config from './config';

const { getLocalStorage } = localStorage;
const { env } = initEnv;

const renderRoutes = (routes) => {
  if (!Array.isArray(routes)) {
    return null;
  }
  return (
    <Switch>
      {routes.map((route, index) => {
        if (route.redirect) {
          return (
            <Redirect
              name={route.name}
              key={route.path || index}
              exact={route.exact}
              strict={route.strict}
              from={route.path}
              to={route.redirect}
            />
          );
        }

        return (
          <Route
            name={route.name}
            key={route.path || index}
            path={route.path}
            exact={route.exact}
            strict={route.strict}
            render={() => {
              const renderChildRoutes = renderRoutes(route.childRoutes);
              if (route.component) {
                return (
                  <Suspense fallback={null}>
                    <route.component route={route}>{renderChildRoutes}</route.component>
                  </Suspense>
                );
              }
              return renderChildRoutes;
            }}
          />
        );
      })}
    </Switch>
  );
};

function usePermission() {
  const globalStore = useStore('global');
  const { permissionValues } = toJS(globalStore);
  const [
    {
      childRoutes: [login, { childRoutes: basicRoutes }],
    },
  ] = config;
  // 利用 useRef 初始化保存值
  const sourceBasicRoutesRef = useRef(basicRoutes);
  const roleObj = JSON.parse(getLocalStorage(`${env}roleObj`));
  const permissionBasicRoutes = useMemo(
    () =>
      sourceBasicRoutesRef.current &&
      sourceBasicRoutesRef.current.map((route) => ({
        ...route,
        permissionValue: roleObj && roleObj[route.name],
      })),
    [roleObj]
  );

  const newBasicRoutes = useMemo(
    () =>
      permissionBasicRoutes &&
      permissionBasicRoutes.filter((route) => permissionValues?.includes(route.permissionValue)),
    [permissionBasicRoutes, permissionValues]
  );
  newBasicRoutes.push(
    { path: '/', exact: true, redirect: '/home' },
    // { path: '*', exact: true, redirect: '/exception/404' }
  );
  config[0].childRoutes[1].childRoutes = [...newBasicRoutes];

  return config;
}

const AppRouter = observer(() => {
  const _config = usePermission();
  console.log(_config, config, '_config,config');
  return <Router>{renderRoutes(_config)}</Router>;
});

export default AppRouter;
