import React from 'react';
import { ConfigProvider, Button } from 'antd';
import { ThemeProvider } from '@material-ui/styles';
import locale from 'antd/lib/locale/zh_CN';
import 'moment/locale/zh-cn';
import { initEnv, localStorage } from '@zxhj/component-library';
import { stores, StoresContext } from '@/model/store';
import AppRouter from './routers/AppRouter';
import theme from './theme';
import './index.css';

const { setLocalStorage, getLocalStorage } = localStorage;
const { env } = initEnv;

function App(props) {
  let getRole = getLocalStorage(`${env}roleObj`) ? JSON.parse(getLocalStorage(`${env}roleObj`)) : {};
  // eslint-disable-next-line no-prototype-builtins
  if (getRole.hasOwnProperty('home主页')) {
    getRole = { ...getRole, 概览: getRole?.['home主页'] };
    delete getRole?.['home主页'];
  }
  setLocalStorage(`${env}roleObj`, getRole);
  return (
    <ThemeProvider theme={theme}>
      <ConfigProvider locale={locale}>
        <StoresContext.Provider value={{ ...stores }}>
          <AppRouter />
        </StoresContext.Provider>
      </ConfigProvider>
    </ThemeProvider>
  );
}

export default App;
