import React from 'react';
import ReactDOM from 'react-dom';
import './reset.css';
import 'antd/dist/antd.css';
import App from './App';
// import API from './utils/initEnv';
import reportWebVitals from './reportWebVitals';

// 强制使用 https 协议
if (buildEnv !== 'native-dev') {
  let url = window.location.href;
  if (url.indexOf('https') < 0) {
    url = url.replace('http:', 'https:');
    window.location.replace(url);
  }
}

// console.log(cookieName, getCookie(cookieName), 'getCookie');

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
