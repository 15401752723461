import { action, makeObservable } from 'mobx';
import BasicModel from '../../basicModel';

export default class CentralWareStock extends BasicModel {
  constructor(props) {
    super({ ...props, baseUrl: 'zl-central-warehouse-stock' });
    makeObservable(this, {
      inStock: action.bound,
      outStock: action.bound,
    });
  }

  // 仓库明细添加商品规格
  async postCentralWarehouseStock(params) {
    await this.appState.fetch(`/${this.baseUrl}`, {
      body: params,
    });
  }

  async inStock(params) {
    await this.appState.fetch(`/${this.baseUrl}/inStock`, {
      body: params,
    });
  }

  async outStock(params) {
    await this.appState.fetch(`/${this.baseUrl}/outStock`, {
      body: params,
    });
  }
}
