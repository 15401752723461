import { action, observable, computed, makeObservable, toJS, runInAction } from 'mobx';

import { leadInKeys } from '@/utils/utils';
// import BasicModel from '../basicModel';
import NewBasicModel from './newBasicModel';

export default class SaasList extends NewBasicModel {
  userInfo = {};

  constructor(props) {
    super({ ...props, baseUrl: 'saas' });
    makeObservable(this, {
      userInfo: observable,
      resetPassword: action.bound,
    });
  }

  async put(params, callback = () => {}) {
    const data = await this.appState.fetch(`/${this.baseUrl}/`, {
      method: 'PUT',
      body: params,
    });
    runInAction(() => {
      this.modalData = data;
      callback();
      this.userInfo = data;
    });
  }

  async resetPassword(data) {
    const result = await this.appState.fetch(`/account/reset-password`, {
      body: data,
    });
    this.userInfo = result;
  }
}
