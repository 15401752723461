import { appState } from '@zxhj/component-library';

import RentStandardGoodsModel from './standardGoods';
import RentCategory from './category';
import RentBrand from './brand';
import RentRepertorySpu from './repertorySpu';

class Store {
  appState = appState;

  repertorySpuModel = new RentRepertorySpu({ appState: this.appState });

  standardGoodsModel = new RentStandardGoodsModel({ appState: this.appState });

  categoryModel = new RentCategory({ appState: this.appState });

  brandModel = new RentBrand({ appState: this.appState });
}

export default Store;
