import { action, observable, computed, makeObservable, toJS, runInAction } from 'mobx';
import BasicModel from '../../basicModel';

export default class Stock extends BasicModel {
  // allExpandData = [];
  selectedSpus = [];

  preSpus = [];

  type = 1;

  standardGoodsModal = false;

  // 编辑规格选择项
  selectedSkus = [];

  inToStockParams = {};

  deviceparams = {};

  categoryList = [];

  constructor(props) {
    super({ ...props });
    makeObservable(this, {
      // allExpandData: observable,
      selectedSpus: observable,
      selectedSkus: observable,
      preSpus: observable,
      inToStockParams: observable,
      standardGoodsModal: observable,
      type: observable,
      deviceparams: observable,
      categoryList: observable,
      putCentralWarehouse: action.bound,
      updateMaxStock: action.bound,
      deleteDevice: action.bound,
      updateDevice: action.bound,
      addDevice: action.bound,
      getCateList: action.bound,
    });
  }

  // 实时仓添加商品
  async putCentralWarehouse(spus) {
    await this.appState.fetch(`/${this.baseUrl}`, {
      body: spus,
    });
  }

  // 实时仓添加商品
  async onInit() {
    const { records, total } = await this.appState.fetch(
      this.type === 1 ? `/${this.baseUrl}` : `/${this.baseUrl}/getMajorInstrumentList`,
      {
        method: 'GET',
        search: this.requestData,
      }
    );
    runInAction(() => {
      this.records = records;
      this.total = total;
    });
  }

  async updateMaxStock() {
    await this.appState.fetch('/zl-central-warehouse-stock/updateCentralWarehouseStock', {
      method: 'POST',
    });
    runInAction(() => {
      this.expandedRowKeys = [];
      this.allExpandData = {};
    });
  }

  async deleteDevice(id) {
    await this.appState.fetch(`/zl-major-instrument/${id}`, {
      method: 'DELETE',
    });
  }

  async updateDevice(data) {
    await this.appState.fetch(`/zl-major-instrument/${data.id}`, {
      method: 'PATCH',
      body: data,
    });
  }

  async addDevice(data) {
    await this.appState.fetch(`/zl-central-warehouse-stock/majorInstrumentInStock`, {
      method: 'POST',
      body: data,
    });
  }

  async getCateList() {
    const data = await this.appState.fetch(`/project-store-category/zl/findAllCategory`, {
      method: 'GET',
    });
    runInAction(() => {
      this.categoryList = data.map((key) => ({
        label: key.name,
        value: key.id,
        children:
          key?.childrenCategory?.map((ite) => ({
            label: ite.name,
            value: ite.id,
            children:
              ite?.childrenCategory?.map((i) => ({
                label: i.name,
                value: i.id,
              })) || [],
          })) || [],
      }));
    });
  }
}
