import { action, observable, computed, makeObservable, toJS, runInAction } from 'mobx';

import { filterKeys, leadInKeys } from '@/utils/utils';
import { spuSchema, skuSchema } from '../schema/spuSchema';

const allPicSchema = ['url'];

export default class Category {
  modalVisible = false;

  modalMode = 'detail'; // detail  详情 || add 新增 || edit 编辑

  modalData = {};

  onInit;

  requestBtn;

  editCategory = '';

  oneCategoryList = [];

  twoCategoryList = [];

  threeCategoryList = [];

  oneCategoryId = false;

  twoCategoryId = false;

  threeCategoryId = false;

  requestData = {};

  constructor(params) {
    this.baseUrl = params.baseUrl || 'category';
    this.appState = params.appState;
    makeObservable(this, {
      editCategory: observable,
      oneCategoryList: observable,
      twoCategoryList: observable,
      threeCategoryList: observable,
      oneCategoryId: observable,
      twoCategoryId: observable,
      threeCategoryId: observable,
      requestData: observable,
      // 弹窗相关属性
      modalVisible: observable,
      modalMode: observable,
      modalData: observable,
      openModal: action.bound,
      closeModal: action.bound,
      updateModalData: action.bound,
      delete: action.bound,
      onOneCategoryList: action.bound,
      findChildCategory: action.bound,
      initData: action.bound,
      updateCategorys: action.bound,
    });
  }

  async initData(dataName, data) {
    this[dataName] = data;
  }

  async updateDate(dataName, key, value) {
    this[dataName][key] = value;
  }

  async findChildCategory(id, listKey) {
    const data = await this.appState.fetch(`/${this.baseUrl}/${id}`, {
      method: 'GET',
    });

    runInAction(() => {
      listKey === 'twoCategoryList' ? (this.twoCategoryList = data) : (this.threeCategoryList = data);
    });
  }

  async onOneCategoryList() {
    const { records } = await this.appState.fetch(`/${this.baseUrl}/pageOneCategory`, {
      method: 'GET',
      search: {
        pageNum: 1,
        pageSize: 10,
        name: this.requestData.name,
      },
    });
    runInAction(() => {
      this.oneCategoryList = records;
    });
  }

  async openModal(type) {
    this.modalMode = type;
    this.modalVisible = true;
  }

  async closeModal() {
    this.modalVisible = false;
    this.modalMode = 'detail';
  }

  async patch(data, id) {
    await this.appState.fetch(`/${this.baseUrl}/${  id}`, {
      method: 'PATCH',
      body: data,
    });
  }

  async put(data) {
    await this.appState.fetch(`/${this.baseUrl}/`, {
      method: 'PUT',
      body: data,
    });
  }

  async delete(id) {
    await this.appState.fetch(`/${this.baseUrl}/${  id}`, {
      method: 'DELETE',
    });
  }

  async updateCategorys(data) {
    await this.appState.fetch(`/${this.baseUrl}/updateCategorys`, {
      method: 'PATCH',
      body: data,
    });
  }

  updateModalData(key, value) {
    this.modalData = {
      ...this.modalData,
      [key]: value,
    };
  }
}
