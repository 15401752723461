import { appState } from '@zxhj/component-library';

import MaintainRecord from './maintainRecord';

class Store {
  appState = appState;

  MaintainRecordModel = new MaintainRecord({ appState: this.appState });
}

export default Store;
