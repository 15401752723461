import { appState } from '@zxhj/component-library';

import Stock from './stock';
import AddSold from './addSold';
// import UnOnlineList from './unOnlineList';
import CentralWareStock from './centralWareStock';
import StockOperationDetails from './stockOperationDetails';

class Store {
  appState = appState;

  // 库存
  stockModel = new Stock({ appState: this.appState, baseUrl: 'centralWarehouse' });

  bargainingWarehouseModel = new Stock({ appState: this.appState, baseUrl: 'bargainingWarehouse' });

  // 上架商品
  addSoldCentralModel = new AddSold({ appState: this.appState, tradeType: 1 });

  addSoldBargainModel = new AddSold({ appState: this.appState, tradeType: 2 });

  // 存在库存未上架的商品
  // unCentralOnlineListModel = new UnOnlineList({
  //   appState: this.appState,
  //   baseUrl: '/centralWarehouse/getUnOnlineReallist',
  // });
  // unBargainOnlineListModel = new UnOnlineList({
  //   appState: this.appState,
  //   baseUrl: '/bargainingWarehouse/getUnOnlineBargaininglist',
  // });

  centralWareStockModel = new CentralWareStock({ appState: this.appState });

  stockOperationDetailsModel = new StockOperationDetails({ appState: this.appState });
}

export default Store;
