const theme = {
  containerPd: {
    root: 24,
  },
  wrapperPd: {
    common: 24,
    card: '16px 24px 16px 24px',
  },
  bgColor: {
    content: '#fafafa',
    card: '#ffffff',
  },
  ySpace: {
    searchGroup: 24,
    content: 16,
    formItem: 12,
    textItem: 0,
    text: 8,
  },
  xSpace: {
    btn: 20,
  },
  font: {
    title: {
      fontSize: 16,
      fontWeight: 'bold',
    },
  },
};

export default theme;
