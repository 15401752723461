const spuSchema = [
  // "allPic",
  'allPicList',
  'brandId',
  'budgetMatchFieldId',
  'categoryId',
  'code',
  'mainPic',
  'skuList',
  'title',
  'type',
  'unitId',
  'tradeType',
  'detailPic',
  'isOperationRecord',
  'minRentTerms',
  'minRentNums',
];

const skuSchema = [
  'id',
  'code',
  'price',
  'spec',
  'sort',
  'centralWarehouseStock',
  'skuIsOnline',
  // "spuId",
];

export { spuSchema, skuSchema };
