import { message } from 'antd';
import { utils } from '@zxhj/component-library';

export const { peerRequestData } = utils;
export const { deserialization } = utils;
// 删除 obj 对象里面 value 为 undefined，null，''
export const removeEmptyField = (obj) => {
  let newObj = {};
  if (typeof obj === 'string') {
    // eslint-disable-next-line no-param-reassign
    obj = JSON.parse(obj);
  }
  if (obj instanceof Array) {
    newObj = [];
  }
  if (obj instanceof Object) {
    // eslint-disable-next-line no-restricted-syntax
    for (const attr in obj) {
      if (Object.hasOwnProperty.call(obj, attr) && obj[attr] !== '' && obj[attr] !== null && obj[attr] !== undefined) {
        if (obj[attr] instanceof Object) {
          newObj[attr] = removeEmptyField(obj[attr]);
        } else if (
          typeof obj[attr] === 'string' &&
          ((obj[attr].indexOf('{') > -1 && obj[attr].indexOf('}') > -1) ||
            (obj[attr].indexOf('[') > -1 && obj[attr].indexOf(']') > -1))
        ) {
          try {
            const attrObj = JSON.parse(obj[attr]);
            if (attrObj instanceof Object) {
              newObj[attr] = removeEmptyField(attrObj);
            }
          } catch (e) {
            newObj[attr] = obj[attr];
          }
        } else {
          newObj[attr] = obj[attr];
        }
      }
    }
  }
  return newObj;
};
// 过滤key有关的键值
export const filterKeys = (targetObj = {}, keys = []) => {
  Object.keys(targetObj).forEach((key) => {
    if (keys.indexOf(key) > -1) {
      // eslint-disable-next-line no-param-reassign
      delete targetObj[key];
    }
  });
  return targetObj;
};
// 导入keys有关的键值
export const leadInKeys = (targetObj = {}, keys = []) => {
  const newObj = {};
  Object.keys(targetObj).forEach((key) => {
    if (keys.indexOf(key) > -1) {
      newObj[key] = targetObj[key];
    }
  });
  return newObj;
};
// 正则校验对象中的key
export const requireKeysFn = (targetObj = {}, requireKeys = []) => {
  return requireKeys.every((curKey) => {
    const curMsg = curKey.message;
    // eslint-disable-next-line no-empty
    if (targetObj[curKey.requireKeyName]) {
    } else {
      message.error(curMsg);
    }
    return Boolean(targetObj[curKey.requireKeyName]);
  });
};
/* <Table
  scroll={{
    x: calcScrollX(columns),
  }}
/> */

// 表格表格根据columns宽度自动生成滚动条
export const calcScrollX = (columns) => {
  let scrollX = 0;
  columns.forEach((col) => {
    const colWid = col.width || 0;
    scrollX += colWid;
  });
  return scrollX;
};
// 查找源对象 目标对象 对象识别符
export function myFindIndex(objArr = [], targetObj = {}, type) {
  const arrIndex = objArr.findIndex((item) => {
    // 这里的判断条件按你需求来写
    return item[type] === targetObj[type];
  });
  return arrIndex;
}
export function search(searchObj) {
  return `?${Object.keys(searchObj)
    .map((key) => `${key}=${searchObj[key]}`)
    .join('&')}`;
}

export function toDateText(timeText) {
  return timeText && timeText.slice(0, 10);
}

// eslint-disable-next-line consistent-return
export function verifyField(item, field, min, max) {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in item) {
    if (Object.hasOwnProperty.call(item, key)) {
      const value = item[key];
      if (key === field) {
        if (value >= min && value <= max) {
          return true;
        }
        return false;
      }
    }
  }
}

export function dataToFixed(num, scale = 2) {
  if (!num || num <= 0) {
    return 0;
  }
  return parseFloat(num).toFixed(scale);
}

// 校验列表整体 对象中的key 输出： true 则通过，false 则不通过；
export const verifyList = (targetObj = {}, requireKeys = []) =>
  requireKeys.every((curKey) => {
    const { message: curMsg, requireKeyName, min, max } = curKey;
    if (verifyField(targetObj, requireKeyName, min, max) === false) {
      // // console.log(verifyField(targetObj, requireKeyName, min, max), '校验');
      message.error(curMsg);
      return false;
    }
    return true;
  });

// 计算成本单价
export const saleConstPrice = (price, ratio = 10) => {
  const newPrice = parseFloat(price);
  if (Number.isNaN(newPrice)) {
    return 0;
  }
  return price === 0 ? null : dataToFixed(price / (1 + ratio / 100));
};

// 计算销售单价
export const computeSalePrice = (price, ratio = 10) => {
  const newPrice = parseFloat(price);
  if (Number.isNaN(newPrice)) {
    return 0;
  }
  return price === 0 ? 0 : parseFloat(dataToFixed(newPrice * (1 + ratio / 100)));
};

// 计算销售加点
export const saleRatio = (firstPrice, secondPrice) => {
  const costPrice = parseFloat(firstPrice);
  const sellPrice = parseFloat(secondPrice);
  if (Number.isNaN(costPrice) || Number.isNaN(sellPrice)) {
    return 0;
  }
  return sellPrice === 0 ? 0 : Math.round((sellPrice / costPrice - 1) * 10000) / 100.0;
};

export function limitDecimals2(value) {
  // eslint-disable-next-line no-useless-escape
  const reg = /^(\-)*(\d+)\.(\d\d).*$/;
  if (typeof value === 'string') {
    return !Number.isNaN(Number(value)) ? value.replace(reg, '$1$2.$3') : '';
  }
  if (typeof value === 'number') {
    return !Number.isNaN(value) ? String(value).replace(reg, '$1$2.$3') : '';
  }
  return '';
}
