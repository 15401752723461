import { makeObservable, observable, runInAction, action } from 'mobx';
import StandardGoodsModel from '../../spuLibrary/standardGoods';

export default class RentStandardGoodsModel extends StandardGoodsModel {
  type = 1;

  warningVisible = false;

  warningUserVisible = false;

  warnUserList = [];

  warnList = [];

  warnModalData = {};

  deviceVisible = false;

  deviceModalData = {};

  constructor(props) {
    super({
      ...props,
      baseUrl: 'zl-lease',
    });
    makeObservable(this, {
      type: observable,
      warningVisible: observable,
      warnList: observable,
      warnModalData: observable,
      warningUserVisible: observable,
      deviceVisible: observable,
      deviceModalData: observable,
      editWarningConfig: action.bound,
      warningInit: action.bound,
      getPlatformUsers: action.bound,
      setEnableStatus: action.bound,
      getDeviceInfo: action.bound,
      editDeviceInfo: action.bound,
      batchExport: action.bound,
    });
  }

  async onInit() {
    const { records, total } = await this.appState.fetch(
      this.type === 1 ? `/${this.baseUrl}/pageLeaseList` : `/${this.baseUrl}/pageLargeLeaseList`,
      {
        method: 'POST',
        search: this.requestData,
      }
    );
    runInAction(() => {
      this.records = records;
      this.total = total;
    });
  }

  async getDetail(id) {
    const data = await this.appState.fetch(`/zl-saas-purchase/${id}`, {
      method: 'GET',
    });
    this.modalData = data;
  }

  // 预警相关
  // 初始化
  async warningInit() {
    const data = await this.appState.fetch(`/car-white-warning/setWarn`, {
      method: 'GET',
    });
    runInAction(() => {
      this.warnList = [data];
    });
  }

  async getPlatformUsers(params = {}) {
    const { records } = await this.appState.fetch(`/userDetail/pageExpireUser`, {
      method: 'GET',
      search: params,
    });
    runInAction(() => {
      this.warnUserList = records;
      const newArr = records.filter((key) => key.warnType) || [];
      this.warnModalData = {
        ...this.warnModalData,
        peopleNums: newArr.map((key) => key.accountId) || [],
        peopleNames: newArr.map((key) => key.nickname) || [],
        carWhiteTypeList: newArr,
      };
      this.warningUserVisible = true;
    });
  }

  async setEnableStatus(data) {
    await this.appState.fetch(`/car-white-warning/setEnableStatus`, {
      method: 'POST',
      formData: data,
    });
  }

  async editWarningConfig(data) {
    await this.appState.fetch(`/car-white-warning/save`, {
      method: 'POST',
      body: data,
    });
    runInAction(() => {
      this.warningUserVisible = false;
      this.warningInit();
    });
  }

  // 设备信息
  async getDeviceInfo(params) {
    const data = await this.appState.fetch(
      `/zl-lease-install-info/getInstallInfo/${params.leaseId}/${params.majorId}`,
      {
        method: 'GET',
      }
    );
    runInAction(() => {
      this.deviceModalData = {
        ...data,
        ...this.deviceModalData,
        orderStatus: params.orderStatus,
      };
      this.deviceVisible = true;
    });
  }

  async editDeviceInfo(data) {
    await this.appState.fetch(data.id ? `/zl-lease-install-info/edit` : `/zl-lease-install-info/add`, {
      method: 'POST',
      search: data,
    });
    runInAction(() => {
      this.deviceVisible = false;
    });
  }

  async batchExport(data) {
    await this.appState.fetch(`/zl-lease/batchExport`, {
      body: data,
      method: 'GET',
      search: {
        ...this.requestData,
        pageNum: 1,
        pageSize: 1000,
      },
    });
    // console.log(result, 'result')
  }
}
