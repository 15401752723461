import { action, observable, computed, makeObservable, toJS } from 'mobx';

import BasicModel from '../../basicModel';

export default class UnOnlineList extends BasicModel {
  constructor(props) {
    super({ ...props });
  }

  async onInit() {
    const { records, total } = await this.appState.fetch(`${this.baseUrl}`, {
      method: 'GET',
      search: toJS(this.requestData),
    });
    this.records = records;
    this.total = total;
  }
}
