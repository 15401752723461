import { runInAction } from 'mobx';
import StandardGoodsModel from '../../spuLibrary/standardGoods';
import { spuSchema, skuSchema } from '../../schema/spuSchemaRent';
import { leadInKeys } from '@/utils/utils';

export default class RentStandardGoodsModel extends StandardGoodsModel {
  constructor(props) {
    super({
      ...props,
      baseUrl: 'zl-spu',
    });
  }

  async put(data) {
    let newSpu = leadInKeys(data, spuSchema);
    newSpu = {
      ...newSpu,
      skuList: newSpu.skuList && newSpu.skuList.map((sku) => leadInKeys(sku, skuSchema)),
    };
    await this.appState.fetch(`/${this.baseUrl}/`, {
      method: 'PUT',
      body: newSpu,
    });
    runInAction(() => {
      this.modalData = data;
    });
  }

  async patch(data, id) {
    let newSpu = leadInKeys(data, spuSchema);
    newSpu = {
      ...newSpu,
      skuList: newSpu.skuList && newSpu.skuList.map((sku) => leadInKeys(sku, skuSchema)),
    };
    await this.appState.fetch(`/${this.baseUrl}/${id}`, {
      method: 'PATCH',
      body: newSpu,
    });
    runInAction(() => {});
  }

  async getCateList() {
    const data = await this.appState.fetch(`/project-store-category/zl/findAllCategory`, {
      method: 'GET',
    });
    runInAction(() => {
      this.categoryList = data.map((key) => ({
        label: key.name,
        value: key.id,
        children:
          key?.childrenCategory?.map((ite) => ({
            label: ite.name,
            value: ite.id,
            children:
              ite?.childrenCategory?.map((i) => ({
                label: i.name,
                value: i.id,
              })) || [],
          })) || [],
      }));
    });
  }
}
