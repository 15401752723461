import { action, observable, computed, makeObservable, toJS } from 'mobx';
import BasicModel from '../basicModel';

export default class Device extends BasicModel {
  initRequestData = {
    pageSize: 10,
    pageNum: 1,
    deviceTypeId: 2,
  };

  constructor(props) {
    super({ ...props, baseUrl: 'device' });
    makeObservable(this, {});
  }

  async initStatic() {
    const data = await this.appState.fetch(`/device-type`, {
      method: 'GET',
    });
    this.defaultStatic.deviceTypes = data;
  }
}
