import { action, observable, computed, makeObservable, toJS } from 'mobx';
import { appState } from '@zxhj/component-library';
import BasicModel from '../basicModel';
// 选择商品的弹窗涉及的model
export default class AccountStoreModel extends BasicModel {
  constructor(props) {
    super({
      ...props,
      baseUrl: 'spu',
    });
    makeObservable(this, {
      selectIds: observable,
      permissRoles: observable,
    });
    this.appState = appState;
  }

  selectIds = [];

  permissRoles = [];
}
