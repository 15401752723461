import { action, observable, computed, makeObservable, toJS } from 'mobx';
import BasicModel from '../../basicModel';

export default class RentRepertorySpu extends BasicModel {
  constructor(props) {
    super({
      ...props,
      initRequestData: {
        pageNum: 1,
        pageSize: 10,
      },
      baseUrl: 'zl-spu',
    });
  }

  async onInit() {
    const { records, total } = await this.appState.fetch(`/${this.baseUrl}/getRepertoryList`, {
      method: 'GET',
      search: toJS(this.requestData),
    });
    this.records = records;
    this.total = total;
  }
}
