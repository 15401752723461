import { action, observable, computed, makeObservable, toJS } from 'mobx';
import BasicModel from '../../basicModel';

export default class SliderBanner extends BasicModel {
  relateTypes = [
    {
      label: '跳转到类别',
      value: 3,
    },
    {
      label: '跳转到URL',
      value: 2,
    },
    {
      label: '跳转到商品',
      value: 1,
    },
  ];

  constructor(props) {
    super({ ...props, baseUrl: 'banner' });
    makeObservable(this, {
      categoryList: observable,
      productData: observable,
      getCategoryList: action.bound,
      getProductDetail: action.bound,
    });
  }

  categoryList = [];

  productData = [];

  async onInit() {
    // console.log(toJS(this.requestData), 'this.requestData')
    const data = await this.appState.fetch(`/${this.baseUrl}/zl`, {
      method: 'GET',
      search: toJS(this.requestData),
    });

    this.records = data;
  }

  async put(data) {
    await this.appState.fetch(`/${this.baseUrl}/`, {
      method: 'PUT',
      body: {
        ...data,
        shopType: 2,
      },
    });
    this.modalData = data;
  }

  async patch(data, id) {
    await this.appState.fetch(`/${this.baseUrl}/${  id}`, {
      method: 'PATCH',
      body: {
        ...data,
        shopType: 2,
      },
    });
  }

  async getCategoryList(requestData) {
    const { records = [] } = await this.appState.fetch(`/category/zl/pageThreeCategory`, {
      method: 'GET',
      search: requestData,
    });
    this.categoryList = records;
  }

  async getProductDetail(id) {
    const data = await this.appState.fetch(`/zl-spu/${  id}`, {
      method: 'GET',
    });
    this.productData = data;
  }
}
