import { toJS } from 'mobx';
import BasicModel from '../../basicModel';

export default class settlementSaas extends BasicModel {
  constructor(props) {
    super({
      ...props,
      initRequestData: {
        pageSize: 10,
        pageNum: 1,
      },
      baseUrl: 'zlSettlementSaas',
    });
  }

  // 新增结算
  async toPayMoment(data, id) {
    await this.appState.fetch(`/${this.baseUrl}`, {
      method: 'PUT',
      body: data,
    });
  }

  async onInit() {
    const { records, total } = await this.appState.fetch(`/zl-saas-purchase`, {
      method: 'GET',
      search: toJS(this.requestData),
    });

    this.records = records;
    this.total = total;
  }

  async initStatic() {
    const data = await this.appState.fetch(`/zl-saas-purchase/init`, {
      method: 'GET',
    });
    this.defaultStatic = data;
  }

  // 开票
  async toInvoicing(data, id) {
    await this.appState.fetch(`/${this.baseUrl}/invoicing/${id}`, {
      method: 'PATCH',
      search: {
        invoicingPic: data.invoicingPic,
      },
      body: {
        recipientAddress: data.recipientAddress,
        recipientName: data.recipientName,
        recipientPhone: data.recipientPhone,
        remark: data.remark,
      },
    });
  }

  // 完成结算单
  async doneSettlement(id) {
    await this.appState.fetch(`/${this.baseUrl}/purchaseDone/${id}`, {
      method: 'PATCH',
    });
  }

  async getAllExpandDetail() {
    const ids = Object.keys(this.allExpandData);
    const obj = {};

    for (let index = 0; index < ids.length; index++) {
      const id = ids[index];
      // eslint-disable-next-line no-await-in-loop
      const data = await this.appState.fetch(`/${this.baseUrl}/${id}`, {
        method: 'GET',
      });
      obj[id] = data;
    }
    console.log(obj);
    this.allExpandData = obj;
  }

  async updateExpandDetail(id) {
    const data = await this.appState.fetch(`/${this.baseUrl}/getByPurchaseId/${id}`, {
      method: 'GET',
    });

    this.allExpandData = {
      ...this.allExpandData,
      [id]: data,
    };
  }

  async getDetail(id) {
    const data = await this.appState.fetch(`/${this.baseUrl}/${id}`, {
      method: 'GET',
    });

    this.modalData = {
      ...data,
      purchaseDTO: {
        ...data.zlPurchaseDTO,
        amount: data.totalprice,
      },
      invoice: data?.settlementDetailDTO?.invoice,
    };
  }

  async getOrderDetail(id) {
    const data = await this.appState.fetch(`/zl-saas-purchase/${id}`, {
      method: 'GET',
    });

    this.modalData = {
      purchaseDTO: data,
    };
  }
}
