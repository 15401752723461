import { makeObservable, runInAction, toJS } from 'mobx';
import { appState } from '@zxhj/component-library';
import BasicModel from './basicModel';

export default class AppVersionModel extends BasicModel {
  constructor(props) {
    super({ ...props, baseUrl: 'app-version-info' });
    this.appState = appState;
    makeObservable(this, {});
  }

  async onInit() {
    const { records, total } = await this.appState.fetch(`/${this.baseUrl}/getList`, {
      method: 'GET',
      search: toJS(this.requestData),
    });

    this.records = records;
    this.total = total;
  }

  async getDetail(data) {
    this.modalData = data;
  }

  async put(data) {
    await this.appState.fetch(`/${this.baseUrl}`, {
      method: 'PUT',
      body: data,
    });
    runInAction(() => {
      this.modalData = data;
    });
  }

  async patch(data, id) {
    await this.appState.fetch(`/${this.baseUrl}/${  id}`, {
      method: 'PATCH',
      body: data,
    });
  }
}
