import React from 'react';
import { Layout } from 'antd';
import { makeStyles } from '@material-ui/styles';

import useJumpLogin from '@/utils/useJumpLogin';
import SiderMenu from '../SiderMenu';
import MainHeader from '../MainHeader';

const { Content } = Layout;

const BasicLayout = ({ route, children }) => {
  const classes = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.bgColor.content,
    },
    content: {
      margin: theme.wrapperPd.card,
      backgroundColor: theme.bgColor.card,
    },
  }))();

  useJumpLogin();

  return (
    <Layout className="main-layout">
      <MainHeader />
      <Layout className={classes.root}>
        <SiderMenu routes={route.childRoutes} />
        <Content className={classes.content} style={{ minHeight: 'calc(100vh - 96px)' }}>
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};

export default BasicLayout;
