import { action, observable, computed, makeObservable, toJS } from 'mobx';

import { leadInKeys } from '@/utils/utils';
import { shipments } from '../schema/purchase';
import BasicModel from '../basicModel';

const quotingSchema = ['price', 'purchaseId'];

export default class WillQuotation extends BasicModel {
  purchaseDTOList = []; // 子表格展示

  // allExpandData = [];
  flowUserId = '';

  purchaseQuote = []; // 报价提交参数

  constructor(props) {
    super({ ...props, baseUrl: 'inquiry' });
    makeObservable(this, {
      purchaseDTOList: observable,
      // allExpandData: observable,
      purchaseQuote: observable,
      // updateExpandDetail: action.bound,
      getAllExpandDetail: action.bound,
      // clearExpandDetail: action.bound,
      putPurchaseQuote: action.bound,
      getFirstFlowUser: action.bound,
    });
  }

  // 覆盖默认 getDetail
  async getDetail(id) {
    const data = await this.appState.fetch(`/${this.baseUrl}/prepurchase/${id}`, {
      method: 'GET',
    });
    this.modalData = data;
  }

  async updateExpandDetail(id) {
    const { purchaseDTOList } = await this.appState.fetch(`/${this.baseUrl}/prepurchase/${id}`, {
      method: 'GET',
    });
    this.allExpandData = {
      ...this.allExpandData,
      [id]: purchaseDTOList,
    };
  }

  async getAllExpandDetail() {
    const ids = Object.keys(this.allExpandData);
    const obj = {};
    for (let index = 0; index < ids.length; index++) {
      const id = ids[index];
      // eslint-disable-next-line no-await-in-loop
      const { purchaseDTOList } = await this.appState.fetch(`/${this.baseUrl}/prepurchase/${id}`, {
        method: 'GET',
      });
      obj[id] = purchaseDTOList;
    }
    this.allExpandData = obj;
  }

  async getFirstFlowUser(projectId) {
    const { userDetailDTOS, permissionDTO } = await this.appState.fetch(
      `/${this.baseUrl}/getFirstFlowUser/${projectId}`,
      {
        method: 'GET',
      }
    );
    this.flowUserId = userDetailDTOS[0].accountId;
  }

  async putPurchaseQuote(prePurchaseId) {
    const purchaseQuote = toJS(this.purchaseQuote);
    await this.appState.fetch(`/${this.baseUrl}/${prePurchaseId}`, {
      method: 'PUT',
      body: purchaseQuote.map((e) => ({
        price: e.price,
        purchaseId: e.id,
      })),
    });
  }

  // 根据订单id查找预下单信息
  async getByPurchaseId(purchaseId) {
    const data = await this.appState.fetch(`/${this.baseUrl}/getByPurchaseId/${purchaseId}`, {
      method: 'GET',
    });
    return data;
  }

  clearExpandDetail(id) {
    delete this.allExpandData[String(id)];
  }

  // getType() {
  //   this.defaultStatic.type.forEach(e => {
  //     if (e.label === "采购流程(议价商品)") {
  //       this.type = e.value;
  //     }
  //   });
  // }
}
