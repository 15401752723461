import { action, makeObservable, observable, toJS, runInAction } from 'mobx';
import BasicModel from '../../basicModel';

// 维修记录
export default class MaintainRecord extends BasicModel {
  deviceList = [];

  constructor(props) {
    super({
      ...props,
      baseUrl: 'zl-operate-manage',
    });

    makeObservable(this, {
      projectList: observable,
      updateRequestData: action.bound,
      onInitRequestData: action.bound,
      getDeviceList: action.bound,
      deviceList: observable,
    });
  }

  projectList = [];

  async onInit() {
    const { records, total } = await this.appState.fetch(`/${this.baseUrl}/pageList`, {
      method: 'POST',
      search: toJS(this.requestData),
    });

    this.records = records;
    this.total = total;
  }

  async put(data) {
    await this.appState.fetch(`/${this.baseUrl}/add`, {
      method: 'POST',
      search: data,
    });
    this.modalData = data;
  }

  async getDetail(id) {
    const data = await this.appState.fetch(`/${this.baseUrl}/get/${id}`, {
      method: 'GET',
    });

    this.modalData = data;
  }

  async getProjectList(params) {
    const { records = [] } = await this.appState.fetch(`/projectSaas`, {
      method: 'GET',
      search: params,
    });

    this.projectList = records;
  }

  updateRequestData(key, value) {
    this.requestData = {
      ...this.requestData,
      [key]: value,
    };
  }

  onInitRequestData() {
    this.requestData = { ...this.initRequestData };
  }

  updateModalData(key, value) {
    this.modalData = {
      ...this.modalData,
      [key]: value,
    };
  }

  async getDeviceList(id) {
    const data = await this.appState.fetch(`/zl-major-instrument/selectBySkuId`, {
      method: 'GET',
      search: { skuId: id },
    });
    runInAction(() => {
      this.deviceList = data;
    });
  }
}
