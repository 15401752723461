import { action, observable, computed, makeObservable, toJS } from 'mobx';
import BasicModel from '../basicModel';

export default class RepertorySpu extends BasicModel {
  constructor(props) {
    super({
      ...props,
      initRequestData: {
        pageNum: 1,
        pageSize: 10,
      },
      baseUrl: 'spu',
    });
    // this.pageSize = 1;
  }

  async onInit() {
    console.log(this, 'this');
    // this.requestData = { ...this.initRequestData };
    const { records, total } = await this.appState.fetch(`/${this.baseUrl}/getRepertoryList`, {
      method: 'GET',
      search: toJS(this.requestData),
    });
    this.records = records;
    this.total = total;
  }
}
