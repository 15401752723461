import { appState } from '@zxhj/component-library';

import GoodsModel from './goods';

class Store {
  appState = appState;

  RentingGoodsModel = new GoodsModel({ appState: this.appState });
}

export default Store;
