import { action, observable, computed, makeObservable, toJS, runInAction } from 'mobx';
import { appState } from '@zxhj/component-library';
import BasicModel from '../basicModel';
// 选择商品的弹窗涉及的model

const removePropertyOfNull = function (obj) {
  Object.keys(obj).forEach((item) => {
    if (obj[item] === ' ') delete obj[item];
  });
  return obj;
};

export default class SupplierStore extends BasicModel {
  categoryData = [];

  constructor(props) {
    super({
      ...props,
      baseUrl: 'jc-supplier',
      initRequestData: {
        pageSize: 10,
        pageNum: 1,
      },
    });
    makeObservable(this, {
      categoryData: observable,
      getCategoryData: action.bound,
      addSupplier: action.bound,
      editSupplier: action.bound,
    });
    this.appState = appState;
  }

  async onInit(params, callback) {
    this.loading = true;
    const data = await this.appState.fetch(`/${this.baseUrl}/pageList`, {
      method: 'POST',
      body: params || this.requestData,
    });

    runInAction(() => {
      callback && callback(data);
      this.loading = false;
      this.records = data?.records || [];
      this.total = data?.total || 0;
    });
  }

  async updateStatus(id, status) {
    await this.appState.fetch(`/${this.baseUrl}/putOnState/{id}?id=${id}`, {
      method: 'GET',
    });
  }

  async getDetail(id) {
    this.loading = true;
    const data = await this.appState.fetch(`/${this.baseUrl}/get/{id}?id=${id}`, {
      method: 'GET',
    });
    this.loading = false;
    this.modalData = {
      ...removePropertyOfNull(data),
      categoryId: data?.categoryList?.map((key) => key.id),
    };
  }

  async auditPass(id, value) {
    this.loading = true;
    const data = await this.appState.fetch(`/${this.baseUrl}/audit`, {
      method: 'POST',
      search: {
        id,
        agreeOrReject: value,
      },
    });
    this.loading = false;
    this.modalVisible = false;
    this.modalData = data;
  }

  async getCategoryData(params = {}) {
    this.loading = true;
    const { records } = await this.appState.fetch(`/category/pageThreeCategory`, {
      method: 'GET',
      search: {
        pageNum: 1,
        pageSize: 50,
        ...params,
      },
    });
    this.loading = false;
    this.categoryData = records.map((key) => ({
      title: key.name,
      value: key.id,
      key: key.id,
      label: key.name,
    }));
  }

  // 添加
  async addSupplier(data) {
    await this.appState.fetch(`/${this.baseUrl}/add`, {
      method: 'POST',
      body: data,
    });
  }

  // 添加
  async editSupplier(data) {
    await this.appState.fetch(`/${this.baseUrl}/edit`, {
      method: 'POST',
      body: data,
    });
  }

  async delete(id) {
    await this.appState.fetch(`/${this.baseUrl}/del/${id}`, {
      method: 'GET',
      search: { id },
    });
  }
}
