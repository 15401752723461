import { useHistory } from 'react-router';

import { cookie, initEnv } from '@zxhj/component-library';

const { cookieName } = initEnv;
const { getCookie } = cookie;

const useJumpLogin = () => {
  const history = useHistory();

  if (!getCookie(cookieName)) {
    history.replace('/login');
  }
};

export default useJumpLogin;
