import { createContext, useContext } from 'react';
import { appState } from '@zxhj/component-library';

import Global from './Global';
import GroupBuyCentralStock from './groupBuyCentralStock';
import SpuLibrary from './spuLibrary';
import PurchaseLib from './purchaseLib';
import Banner from './banner';
import Finance from './finance';
import Saas from './saas';
import SelectedSpus from './selectedSpus';
import Set from './set';
import RentSpu from './rent/rentSpu';
import RentSet from './rent/rentSet';
import RentCenterProduct from './rent/groupBuyCentralStock';
import RentingGoodsModel from './rent/rentingProduct';
import MaintainRecordModel from './rent/maintainRecord';
import SettlementRentModel from './rent/finance';
import SliderBannerModel from './rent/slider';
import AppVersionModel from './appVersionStore';
import SupplierStore from './replenishmentStore/supplierStore';
import AccountStoreModel from './replenishmentStore/accountStore';
import EnquiryStore from './replenishmentStore/enquiryStore';

function createStores() {
  return {
    global: new Global(),
    SpuLibrary: new SpuLibrary(),
    PurchaseLib: new PurchaseLib(),
    GroupBuyCentralStock: new GroupBuyCentralStock(),
    Banner: new Banner(),
    Finance: new Finance(),
    Saas: new Saas(),
    Set: new Set(),
    SelectedSpusModel: new SelectedSpus({ appState }),
    RentSpu: new RentSpu(),
    RentSet: new RentSet(),
    RentCenterProduct: new RentCenterProduct(),
    RentingGoodsModel: new RentingGoodsModel(),
    MaintainRecordModel: new MaintainRecordModel(),
    SettlementRentModel: new SettlementRentModel(),
    SliderBannerRentModel: new SliderBannerModel(),
    AppVersionModel: new AppVersionModel(),
    SupplierStoreModel: new SupplierStore(),
    AccountStoreModel: new AccountStoreModel(),
    EnquiryStore: new EnquiryStore(),
  };
}

const stores = createStores();

const StoresContext = createContext(stores);

const useStore = (store) => {
  // console.log('useStore');
  return useContext(StoresContext)[store];
};

export { StoresContext, stores, useStore };
