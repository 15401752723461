import { action, observable, computed, makeObservable, toJS } from 'mobx';

import { leadInKeys } from '@/utils/utils';
import { shipments } from '../schema/purchase';
import BasicModel from '../basicModel';

export default class CentralWareStock extends BasicModel {
  constructor(props) {
    super({ ...props, baseUrl: 'centralWarehouseStock' });
    makeObservable(this, {
      inStock: action.bound,
      outStock: action.bound,
    });
  }

  // 仓库明细添加商品规格
  async postCentralWarehouseStock(params) {
    await this.appState.fetch(`/${this.baseUrl}`, {
      body: params,
    });
  }

  async inStock(params) {
    await this.appState.fetch(`/${this.baseUrl}/inStock`, {
      body: params,
    });
  }

  async outStock(params) {
    await this.appState.fetch(`/${this.baseUrl}/outStock`, {
      body: params,
    });
  }
}
