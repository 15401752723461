import { appState } from '@zxhj/component-library';

import SettlementSaas from './settlementSaas';

class Store {
  appState = appState;

  settleModel = new SettlementSaas({ appState: this.appState });
}

export default Store;
