import { runInAction, observable, action, makeObservable } from 'mobx';
import BasicModel from '../../basicModel';
// 仓库明细
export default class StockOperationDetails extends BasicModel {
  majorList = [];

  constructor(props) {
    super({ ...props, baseUrl: 'zl-stock-operation-details' });
    makeObservable(this, {
      majorList: observable,
      getMajorInstrument: action.bound,
    });
  }

  async getMajorInstrument(id) {
    const data = await this.appState.fetch(`/zl-major-instrument/selectBySkuId`, {
      method: 'GET',
      search: { skuId: id },
    });
    runInAction(() => {
      this.majorList = data;
    });
  }
}
