import { action, observable, computed, runInAction, makeObservable, toJS } from 'mobx';
import { filterKeys, leadInKeys, peerRequestData } from '@/utils/utils';
import { spuSchema, skuSchema } from '../schema/spuSchema';

class ExpandModel {
  allExpandData = {};

  expandedRowKeys = [];

  constructor() {
    makeObservable(this, {
      allExpandData: observable,
      expandedRowKeys: observable,
      clearExpandDetail: action.bound,
      updateExpandDetail: action.bound,
    });
  }

  clearExpandDetail(id) {
    delete this.allExpandData[String(id)];
  }

  async updateExpandDetail(id) {
    const data = await this.appState.fetch(`/${this.baseUrl}/${id}`, {
      method: 'GET',
    });
    this.allExpandData = {
      ...this.allExpandData,
      [id]: data,
    };
  }
}

export default class StandardGoodsModel extends ExpandModel {
  initRequestData = {
    pageSize: 10,
    pageNum: 1,
  };

  defaultStatic = {};

  requestData = { ...this.initRequestData };

  records = [];

  total = 0;

  modalVisible = false;

  modalMode = 'detail'; // detail  详情 || add 新增 || edit 编辑

  editId;

  modalData = {};

  categoryList = [];
  // editorState = null;

  constructor(props) {
    super(props);
    this.appState = props.appState;
    this.baseUrl = props.baseUrl || 'spu';
    makeObservable(this, {
      // 表格渲染相关属性
      defaultStatic: observable,
      total: observable,
      requestData: observable,
      records: observable,
      // allExpandData: observable,
      // expandedRowKeys: observable,
      // 弹窗相关属性
      modalVisible: observable,
      modalMode: observable,
      editId: observable,
      modalData: observable,
      categoryList: observable,
      // 调用方法
      // clearExpandDetail: action.bound,
      // getAllExpandDetail: action.bound,
      // updateExpandDetail: action.bound,
      initStatic: action.bound,
      onInit: action.bound,
      onInitRequestData: action.bound,
      initModalData: action.bound,
      updateModalData: action.bound,
      updateSkuList: action.bound,
      addSku: action.bound,
      getDetail: action.bound,
      put: action.bound,
      delete: action.bound,
      patch: action.bound,
      rmSku: action.bound,
      openModal: action.bound,
      closeModal: action.bound,
      updateRequestData: action.bound,
      initData: action.bound,
      updateData: action.bound,
      updateStatus: action.bound,
      getCateList: action.bound,
    });
  }

  updateData(dataName, key, value) {
    this[dataName] = {
      ...this[dataName],
      [key]: value,
    };
  }

  initData(dataName, init) {
    this[dataName] = init;
  }

  // updateEditorState(editorState) {
  //   // console.log(editorState, 'editorState');
  //   this.editorState = editorState
  // }

  initModalData(init) {
    this.modalData = init;
  }

  addSku(index) {
    this.modalData.skuList.push({
      spec: '',
      code: '',
      price: 0,
      specUnit: this.modalData.unitText,
      specRatio: 1,
      sort: index,
      index,
      centralWarehouseStock: 0,
      skuIsOnline: 1,
      // id: null,
    });
  }

  updateSkuList(sku, index) {
    this.modalData.skuList.splice(index, 1, sku);
  }

  rmSku(index) {
    this.modalData.skuList.splice(index, 1);
  }

  openModal(modalMode) {
    this.modalVisible = true;
    this.modalMode = modalMode;
  }

  closeModal() {
    this.modalVisible = false;
    this.modalData = {};
  }

  updateModalData(key, value) {
    this.modalData = {
      ...this.modalData,
      [key]: value,
    };
  }

  updateRequestData(key, value) {
    this.requestData = {
      ...this.requestData,
      [key]: value,
    };
  }

  onInitRequestData() {
    this.requestData = { ...this.initRequestData };
  }

  async onInit() {
    const { records, total } = await this.appState.fetch(`/${this.baseUrl}`, {
      method: 'GET',
      search: peerRequestData(this.requestData),
    });
    this.records = records;
    this.total = total;
  }

  async getDetail(id) {
    const data = await this.appState.fetch(`/${this.baseUrl}/${id}`, {
      method: 'GET',
    });
    runInAction(async () => {
      if (data.categoryId) {
        const res1 = await this.getCateDetail(data.categoryId);
        const res2 = await this.getCateDetail(res1.parentId);
        this.modalData = {
          ...data,
          categoryId: [res2.parentId, res1.parentId, data.categoryId],
        };
      } else {
        this.modalData = data;
      }
    });
  }

  async getCateDetail(id) {
    const data = await this.appState.fetch(`/category/getCategory/${id}`, {
      method: 'GET',
    });
    return data;
  }
  // async getAllExpandDetail() {
  //   const ids = Object.keys(this.allExpandData);
  //   const obj = {};

  //   for (let index = 0; index < ids.length; index++) {
  //     const id = ids[index];
  //     const data = await this.appState.fetch(`/${this.baseUrl}/` + id, {
  //       method: 'GET'
  //     });
  //     obj[id] = data;
  //   };
  //   this.allExpandData = obj;
  // }

  // async updateExpandDetail(id) {
  //   const data = await this.appState.fetch(`/${this.baseUrl}/` + id, {
  //     method: 'GET'
  //   });
  //   this.allExpandData = {
  //     ...this.allExpandData,
  //     [id]: data
  //   }
  // }

  // clearExpandDetail(id) {
  //   delete this.allExpandData[String(id)];
  // }

  async put(data) {
    let newSpu = leadInKeys(data, spuSchema);
    newSpu = {
      ...newSpu,
      // mainPic: newSpu.mainPic && newSpu.mainPic.map(pic => leadInKeys(pic, allPicSchema).url)[0],
      // allPicList: newSpu.allPicList && newSpu.allPicList.map(pic => leadInKeys(pic, allPicSchema).url),
      skuList: newSpu.skuList && newSpu.skuList.map((sku) => leadInKeys(sku, skuSchema)),
    };
    await this.appState.fetch(`/${this.baseUrl}/`, {
      method: 'PUT',
      body: newSpu,
    });
    runInAction(() => {
      this.modalData = data;
    });
  }

  async patch(data, id) {
    let newSpu = leadInKeys(data, spuSchema);
    newSpu = {
      ...newSpu,
      // mainPic: newSpu.mainPic && newSpu.mainPic.map(pic => leadInKeys(pic, allPicSchema).url)[0],
      // allPicList: newSpu.allPicList && newSpu.allPicList.map(pic => leadInKeys(pic, allPicSchema).url),
      skuList: newSpu.skuList && newSpu.skuList.map((sku) => leadInKeys(sku, skuSchema)),
    };

    await this.appState.fetch(`/${this.baseUrl}/${id}`, {
      method: 'PATCH',
      body: newSpu,
    });
  }

  async initStatic() {
    const data = await this.appState.fetch(`/${this.baseUrl}/init`, {
      method: 'GET',
    });
    this.defaultStatic = data;
  }

  async delete(id) {
    await this.appState.fetch(`/${this.baseUrl}/${id}`, {
      method: 'DELETE',
    });
  }

  async updateStatus(id, status) {
    await this.appState.fetch(`/${this.baseUrl}/updatestatus/${id}`, {
      search: {
        id,
        status,
      },
    });
  }

  async getCateList() {
    const data = await this.appState.fetch(`/project-store-category/findAllCategory`, {
      method: 'GET',
    });
    runInAction(() => {
      this.categoryList = data.map((key) => ({
        label: key.name,
        value: key.id,
        children:
          key?.childrenCategory?.map((ite) => ({
            label: ite.name,
            value: ite.id,
            children:
              ite?.childrenCategory?.map((i) => ({
                label: i.name,
                value: i.id,
              })) || [],
          })) || [],
      }));
    });
  }
}
