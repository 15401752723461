import { action, observable, computed, makeObservable, toJS, runInAction } from 'mobx';

import BasicModel from '../basicModel';

const quotingSchema = ['price', 'purchaseId'];

export default class Stock extends BasicModel {
  selectedSpus = [];

  preSpus = [];

  standardGoodsModal = false;

  // 编辑规格选择项
  selectedSkus = [];

  inToStockParams = {};

  constructor(props) {
    super({ ...props });
    makeObservable(this, {
      // allExpandData: observable,
      selectedSpus: observable,
      selectedSkus: observable,
      preSpus: observable,
      inToStockParams: observable,
      standardGoodsModal: observable,
      updateMaxStock: action.bound,
      putCentralWarehouse: action.bound,
    });
  }

  async updateMaxStock() {
    await this.appState.fetch(
      this.baseUrl === 'centralWarehouse'
        ? `/centralWarehouseStock/updateCentralWarehouseStock`
        : '/centralWarehouseStock/updateBargainingCentralWarehouseStock',
      {
        method: 'POST',
      }
    );
    runInAction(() => {
      this.expandedRowKeys = [];
      this.allExpandData = {};
    });
  }

  // 实时仓添加商品
  async putCentralWarehouse(spus) {
    await this.appState.fetch(`/${this.baseUrl}`, {
      body: spus,
    });
  }
}
