import { appState } from '@zxhj/component-library';

import SliderBanner from './sliderBanner';
import RecomdCategory from './recomdCategory';

class Store {
  appState = appState;

  sliderBannerModel = new SliderBanner({ appState: this.appState });

  recomdCategoryModel = new RecomdCategory({ appState: this.appState });
}

export default Store;
