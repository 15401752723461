import { action, observable, computed, makeObservable, toJS } from 'mobx';

import StandardGoods from './spuLibrary/standardGoods';

// 选择商品的弹窗涉及的model
class SelectedSpus {
  selectedSpus = [];

  preSpus = [];

  modalVisible = false;

  constructor() {
    makeObservable(this, {
      selectedSpus: observable,
      preSpus: observable,
      modalVisible: observable,
      updateData: observable,
      initData: observable,
    });
  }

  updateData(dataName, key, value) {
    this[dataName] = {
      ...this[dataName],
      [key]: value,
    };
  }

  initData(dataName, init) {
    this[dataName] = init;
  }

  // async onInit() {
  //   const { records, total } = await this.appState.fetch('/spu/getRepertoryList', {
  //     method: 'GET',
  //     search: toJS(this.requestData),
  //   });
  //   this.records = records;
  //   this.total = total;
  // }
}

export default SelectedSpus;
