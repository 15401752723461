import { action, observable, computed, makeObservable, toJS } from 'mobx';
import { appState } from '@zxhj/component-library';
import BasicModel from '../basicModel';
// 选择商品的弹窗涉及的model
export default class EnquiryStore extends BasicModel {
  constructor(props) {
    super({
      ...props,
      baseUrl: 'request-price',
    });
    makeObservable(this, {
      selectIds: observable,
      permissRoles: observable,
      productRecords: observable,
    });
    this.appState = appState;
  }

  selectIds = [];

  permissRoles = [];

  productRecords = [];

  async onInit() {
    this.loading = true;
    const { records, total } = await this.appState.fetch(`/${this.baseUrl}/page/list `, {
      method: 'GET',
      search: toJS(this.requestData),
    });
    this.loading = false;
    this.records = records;
    this.total = total;
  }

  async getProduct(id) {
    const { records } = await this.appState.fetch(`/request-price/getRequestPriceDetail/page/list `, {
      method: 'GET',
      search: toJS({
        pageNum: 1,
        pageSize: 10,
        requestPriceNo: id,
      }),
    });
    this.allExpandData = {
      ...this.allExpandData,
      [id]: records,
    };
  }

  async put(data) {
    await this.appState.fetch(`/${this.baseUrl}/add`, {
      method: 'PUT',
      body: data,
    });
  }

  async getDetail(id) {
    this.loading = true;
    const data = await this.appState.fetch(`/${this.baseUrl}/get/{id}?id=${id}`, {
      method: 'GET',
    });
    this.loading = false;
    this.modalData = {
      ...data,
      categoryId: data?.categoryList?.map((key) => key.id),
    };
  }
}
