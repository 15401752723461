import { action, observable, computed, makeObservable, toJS, runInAction } from 'mobx';
import Category from '../../spuLibrary/category';

export default class RentCategory extends Category {
  constructor(props) {
    super({
      ...props,
      baseUrl: 'category/zl',
    });
  }

  async findChildCategory(id, listKey) {
    const data = await this.appState.fetch(`/category/${id}`, {
      method: 'GET',
    });

    runInAction(() => {
      listKey === 'twoCategoryList' ? (this.twoCategoryList = data) : (this.threeCategoryList = data);
    });
  }

  async patch(data, id) {
    await this.appState.fetch(`/category/${id}`, {
      method: 'PATCH',
      body: data,
    });
  }

  async delete(id) {
    await this.appState.fetch(`/category/${id}`, {
      method: 'DELETE',
    });
  }

  async updateCategorys(data) {
    await this.appState.fetch(`/category/updateCategorys`, {
      method: 'PATCH',
      body: data,
    });
  }
}
