import { action, observable, computed, makeObservable, toJS, runInAction } from 'mobx';

const toModalData = (data) => {
  return {
    ...data,
  };
};

export default class BasicModel {
  initRequestData = {
    pageSize: 10,
    pageNum: 1,
  };

  defaultStatic = {};

  requestData = { ...this.initRequestData };

  records = [];

  total = 0;

  // 展开列表
  expandedRowKeys = [];

  allExpandData = [];

  modalVisible = false;

  modalMode = 'detail'; // detail  onInitRequestData详情 || add 新增 || edit 编辑

  editId;

  modalData = {};

  constructor(params) {
    const { appState, baseUrl } = params;
    this.baseUrl = baseUrl;
    this.appState = appState;
    makeObservable(this, {
      // 表格渲染相关属性
      defaultStatic: observable,
      total: observable,
      records: observable,
      requestData: observable,
      // 子表格人相关属性
      expandedRowKeys: observable,
      allExpandData: observable,
      // 弹窗相关属性
      modalVisible: observable,
      modalMode: observable,
      editId: observable,
      modalData: observable,
      // 调用方法
      initData: action.bound,
      updateData: action.bound,
      updateList: action.bound,
      // 子表格快捷方法
      updateExpandDetail: action.bound,
      clearExpandDetail: action.bound,
      initStatic: action.bound,
      onInit: action.bound,
      put: action.bound,
      patch: action.bound,
      delete: action.bound,
      getDetail: action.bound,
      openModal: action.bound,
      closeModal: action.bound,
    });
  }

  clearExpandDetail(id) {
    delete this.allExpandData[String(id)];
  }

  async updateExpandDetail(id) {
    const data = await this.appState.fetch(`/${this.baseUrl}/${id}`, {
      method: 'GET',
    });
    runInAction(() => {
      this.allExpandData = {
        ...this.allExpandData,
        [id]: data,
      };
    });
  }

  openModal(modalMode) {
    this.modalVisible = true;
    this.modalMode = modalMode;
  }

  closeModal() {
    this.modalVisible = false;
    this.modalData = {};
  }

  updateData(dataName, key, value) {
    this[dataName] = {
      ...this[dataName],
      [key]: value,
    };
  }

  initData(dataName, init) {
    this[dataName] = init;
  }

  updateList(listName, index, item) {
    if (item) {
      this[listName].splice(index, 1, item);
    } else {
      this[listName].splice(index, 1);
    }
  }

  async onInit(data, callback = () => {}) {
    const { records, total } = await this.appState.fetch(`/${this.baseUrl}`, {
      method: 'GET',
      search: data,
    });
    runInAction(() => {
      callback();
      this.records = records;
      this.total = total;
    });
  }

  async getDetail(id, callback = () => {}) {
    const data = await this.appState.fetch(`/${this.baseUrl}/${  id}`, {
      method: 'GET',
    });
    runInAction(() => {
      callback();
      this.modalData = data;
    });
  }

  async put(data, callback = () => {}) {
    await this.appState.fetch(`/${this.baseUrl}/`, {
      method: 'PUT',
      body: data,
    });
    runInAction(() => {
      this.modalData = data;
      callback();
    });
  }

  async patch(data, id, callback = () => {}) {
    await this.appState.fetch(`/${this.baseUrl}/${  id}`, {
      method: 'PATCH',
      body: data,
    });
    runInAction(() => {
      callback();
    });
  }

  async delete(id, callback = {}) {
    await this.appState.fetch(`/${this.baseUrl}/${  id}`, {
      method: 'DELETE',
    });
  }

  async initStatic() {
    const data = await this.appState.fetch(`/${this.baseUrl}/init`, {
      method: 'GET',
    });
    runInAction(() => {
      this.defaultStatic = data;
    });
  }
}
