import React, { lazy } from 'react';
import { HomeOutlined, WarningOutlined, FrownOutlined } from '@ant-design/icons';
import BasicLayout from '@/Layouts/BasicLayout';
import BlankLayout from '@/Layouts/BlankLayout';

const config = [
  {
    path: '/',
    component: BlankLayout, // 空白页布局
    childRoutes: [
      // 子菜单路由
      {
        path: '/login', // 路由路径
        name: '登录页', // 菜单名称 (不设置,则不展示在菜单栏中）
        component: lazy(() => import('@/pages/login')), // 懒加载 路由组件
      },
      {
        path: '/',
        // exact: true,
        component: BasicLayout, // 基本布局
        childRoutes: [
          {
            path: '/home',
            name: '概览',
            icon: <HomeOutlined />,
            component: lazy(() => import('@/pages/home/components/Home')),
          },
          {
            path: '/template',
            name: '测试模板',
            icon: <HomeOutlined />,
            component: lazy(() => import('@/pages/home/components/Template')),
          },
          {
            path: '/groupBuyingGood',
            name: '集采商品库管理',
            icon: <HomeOutlined />,
            childRoutes: [
              {
                path: '/groupBuyingGood/StandardGoods',
                name: '标准商品库',
                component: lazy(() => import('@/pages/home/components/StandardGoods')),
              },
              {
                path: '/groupBuyingGood/CategoryManager',
                name: '类目管理',
                component: lazy(() => import('@/pages/home/components/CategoryManager')),
              },
              {
                path: '/groupBuyingGood/BrandManager',
                name: '品牌管理',
                component: lazy(() => import('@/pages/home/components/BrandManager')),
              },
            ],
          },
          {
            path: '/groupBuyPurchase',
            name: '集采订单管理',
            icon: <HomeOutlined />,
            childRoutes: [
              {
                path: '/groupBuyPurchase/groupBuyPurchaseAll',
                name: '订单管理',
                component: lazy(() => import('@/pages/home/components/PurchaseManager')),
              },
              {
                path: '/groupBuyPurchase/deliveryRecord',
                name: '发货记录',
                component: lazy(() => import('@/pages/home/components/DeliveryRecord')),
              },
              {
                path: '/groupBuyPurchase/willQuotation',
                name: '待报价',
                component: lazy(() => import('@/pages/home/components/WillQuotation')),
              },
            ],
          },
          {
            path: '/replenishmentManage',
            name: '集采补货管理',
            icon: <HomeOutlined />,
            childRoutes: [
              {
                path: '/replenishmentManage/supplierManage',
                name: '供应商报价人管理',
                component: lazy(() => import('@/pages/home/components/replenishmentManage/supplierManage')),
              },
              // {
              //   path: '/replenishmentManage/enquiryManage',
              //   name: '询价管理',
              //   component: lazy(() => import('@/pages/home/components/replenishmentManage/Enquirymanage')),
              // },
              // {
              //   path: '/replenishmentManage/replenishOrder',
              //   name: '补货订单',
              //   component: lazy(() => import('@/pages/home/components/replenishmentManage/replenishmentOrder')),
              // },
            ],
          },
          {
            path: '/groupBuyCentralStock',
            name: '集采中央仓管理',
            icon: <HomeOutlined />,
            childRoutes: [
              {
                path: '/groupBuyCentralStock/centralWarehouse',
                name: '商品价库存管理',
                component: lazy(() => import('@/pages/home/components/GroupBuyStock')),
              },
              {
                path: '/groupBuyCentralStock/bargainingHouse',
                name: '议价库存管理',
                component: lazy(() => import('@/pages/home/components/BargainingHouse')),
              },
              {
                path: '/groupBuyCentralStock/addSoldOutCentral',
                name: '商品价库存上下架管理',
                component: lazy(() => import('@/pages/home/components/AddSoldOutCentral')),
              },
              {
                path: '/groupBuyCentralStock/addSoldOutBargain',
                name: '议价库存上下架管理',
                component: lazy(() => import('@/pages/home/components/AddSoldOutBargain')),
              },
            ],
          },
          {
            path: '/groupBuyGoodRent',
            name: '租赁商品库管理',
            icon: <HomeOutlined />,
            childRoutes: [
              {
                path: '/groupBuyGoodRent/standardGoodsRent',
                name: '标准商品库',
                component: lazy(() => import('@/pages/home/components/rentManage/StandardGoodsRent')),
              },
              {
                path: '/groupBuyGoodRent/categoryManagerRent',
                name: '类目管理',
                component: lazy(() => import('@/pages/home/components/rentManage/CategoryManagerRent')),
              },
              {
                path: '/groupBuyGoodRent/brandManagerRent',
                name: '品牌管理',
                component: lazy(() => import('@/pages/home/components/rentManage/BrandManagerRent')),
              },
            ],
          },
          {
            path: '/leaseCentralStockRent',
            name: '租赁中央仓管理',
            icon: <HomeOutlined />,
            childRoutes: [
              {
                path: '/leaseCentralStockRent/centralWarehouse',
                name: '库存管理',
                component: lazy(() => import('@/pages/home/components/rentManage/GroupBuyStockRent')),
              },
              {
                path: '/leaseCentralStockRent/categoryManagerRent',
                name: '上下架管理',
                component: lazy(() => import('@/pages/home/components/rentManage/AddSoldOutCentralRent')),
              },
              {
                path: '/leaseCentralStockRent/RentingProductManage',
                name: '在租商品管理',
                component: lazy(() => import('@/pages/home/components/rentManage/RentingProductManage')),
              },
              {
                path: '/leaseCentralStockRent/maintenanceRecord',
                name: '维保记录管理',
                component: lazy(() => import('@/pages/home/components/rentManage/MaintenanceRecord')),
              },
            ],
          },
          {
            path: '/leasePurchase',
            name: '租赁订单管理',
            icon: <HomeOutlined />,
            childRoutes: [
              {
                path: '/leasePurchase/leasePurchase',
                name: '订单管理',
                component: lazy(() => import('@/pages/home/lease-components/purchase')),
              },
              {
                path: '/leasePurchase/surrenderTenancy',
                name: '退租管理',
                component: lazy(() => import('@/pages/home/lease-components/surrenderTenancy')),
              },
            ],
          },
          {
            path: '/advertBanner',
            name: '广告banner管理',
            icon: <HomeOutlined />,
            childRoutes: [
              {
                path: '/advertBanner/sliderBanner',
                name: '集采轮播banner设置',
                component: lazy(() => import('@/pages/home/components/SliderBanner')),
              },
              // {
              //   path: "/advertBanner/recommendCategory",
              //   name: "集采首页推荐类目配置",
              //   component: lazy(() => import("@/pages/home/components/RecomdCategory"))
              // },
              {
                path: '/advertBanner/sliderBannerRent',
                name: '租赁轮播banner设置',
                component: lazy(() => import('@/pages/home/components/rentManage/SliderBannerRent')),
              },
              // {
              //   path: "/advertBanner/recomdCategoryRent",
              //   name: "租赁首页推荐类目配置",
              //   component: lazy(() => import("@/pages/home/components/rentManage/RecomdCategoryRent"))
              // }
            ],
          },
          {
            path: '/financial',
            name: '财务管理',
            icon: <HomeOutlined />,
            childRoutes: [
              {
                path: '/financial/settlementSaas',
                name: '集采结算管理',
                component: lazy(() => import('@/pages/home/components/SettlementSaas')),
              },
              {
                path: '/financial/settlementSaasRent',
                name: '租赁结算管理',
                component: lazy(() => import('@/pages/home/components/rentManage/SettlementSaasRent')),
              },
            ],
          },
          {
            path: '/account',
            name: '账号管理',
            icon: <HomeOutlined />,
            childRoutes: [
              {
                path: '/account/list',
                name: '账号管理',
                component: lazy(() => import('@/pages/home/components/AccountList')),
              },
            ],
          },
          {
            path: '/saas',
            name: '项目方管理',
            icon: <HomeOutlined />,
            childRoutes: [
              {
                path: '/saas/saasList',
                name: '项目方列表',
                component: lazy(() => import('@/pages/home/components/SaasList')),
              },
              // {
              //   path: '/saas/device',
              //   name: '设备管理',
              //   component: lazy(() => import('@/pages/home/components/Device')),
              // },
            ],
          },
          {
            path: '/setting',
            name: '设置',
            icon: <HomeOutlined />,
            childRoutes: [
              {
                path: '/setting/groupBuyUnit',
                name: '集采商城单位字典管理',
                component: lazy(() => import('@/pages/home/components/GroupBuyUnit')),
              },
              // {
              //   path: '/setting/groupBuyBudgetMatchField',
              //   name: '集采商城预算字典管理',
              //   component: lazy(() => import('@/pages/home/components/GroupBuyBudgetMatchField')),
              // },
              {
                path: '/setting/groupBuyUnitRent',
                name: '租赁商城单位字典管理',
                component: lazy(() => import('@/pages/home/components/rentManage/GroupBuyUnitRent')),
              },
              // {
              //   path: '/setting/groupBuyBudgetMatchFieldRent',
              //   name: '租赁商城预算字典管理',
              //   component: lazy(() => import('@/pages/home/components/rentManage/GroupBuyBudgetMatchFieldRent')),
              // },
              {
                path: '/setting/appVersion',
                name: 'APP版本管理',
                component: lazy(() => import('@/pages/home/components/AppVersion')),
              },
            ],
          },
          // {
          //   path: '/exception',
          //   name: '异常页',
          //   // exact: true,
          //   icon: <WarningOutlined />,
          //   childRoutes: [
          //     {
          //       path: '/exception/404',
          //       name: '404',
          //       exact: true,
          //       icon: <FrownOutlined />,
          //       component: lazy(() => import('@/pages/Exception/404')),
          //     },
          //   ],
          // },
          { path: '/', exact: true, redirect: '/home' },
          { path: '*', exact: true, redirect: '/exception/404' },
        ],
      },
    ],
  },
];

export default config;
