import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { Menu, Layout, Dropdown } from 'antd';

import { UserOutlined } from '@ant-design/icons';
import { initEnv, cookie, localStorage } from '@zxhj/component-library';
import routerConfig from '@/routers/config';

const { Header } = Layout;
const { cookieName, env } = initEnv;
const { removeLocalStorage, getLocalStorage } = localStorage;
const { delCookie } = cookie;

function findRouteName(routes = [], pathname) {
  let title = '';
  // 遍历外层节点
  for (let index = 0; index < routes.length; index++) {
    const item = routes[index];
    if (item.path === pathname) {
      title = item.name;
      return title;
    }
    // 遍历子节点
    if (item.childRoutes && item.childRoutes.length > 0) {
      const childResult = findRouteName(item.childRoutes, pathname);
      // 子节点存在值情况下 return
      if (childResult && childResult.length > 0) {
        title = childResult;
        return title;
      }
    }
  }
}

const MainHeader = (props) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const [
    {
      childRoutes: [login = {}, { childRoutes: adminRoutes }],
    },
  ] = routerConfig;
  // console.log(adminRoutes, pathname, 'adminRoutes,pathname,');
  const name = findRouteName(adminRoutes, pathname);
  // console.log(name, 'name');

  return (
    <Header style={{ backgroundColor: '#fff', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
      <div style={{ width: 130 }}>
        <img alt="" style={{ width: '100%' }} src={`${process.env.PUBLIC_URL}/assets/images/logo.png`} />
      </div>
      <div>{name}</div>
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item
              key="quit"
              onClick={() => {
                delCookie(cookieName);
                removeLocalStorage(`${env}ZXHJ-permissionValues`);
                removeLocalStorage(`${env}ZXHJ-username`);
                removeLocalStorage(`${env}roleObj`);
                history.replace('/login');
              }}
            >
              退出账号
            </Menu.Item>
          </Menu>
        }
        placement="bottomCenter"
        arrow
      >
        <div>
          <UserOutlined style={{ width: 40, height: 40, fontSize: 24, lineHeight: '40px' }} />
          <span style={{ fontSize: 14 }}>{getLocalStorage(`${env}ZXHJ-username`)}</span>
        </div>
      </Dropdown>
    </Header>
  );
};

export default MainHeader;
