import { action, observable, computed, makeObservable, toJS, autorun } from 'mobx';
import BasicModel from '../basicModel';

export default class RecomdCategory extends BasicModel {
  firstCategorys = [];

  tradeType = 1;

  addSpuCategory;
  // modalDataSource;

  constructor(props) {
    super({ ...props, baseUrl: 'category-config' });

    makeObservable(this, {
      addSpuCategory: observable,
      tradeType: observable,
      firstCategorys: observable,
      getFirstCategory: action.bound,
    });
  }

  async getDetail() {
    const data = await this.appState.fetch(`/${this.baseUrl}/getThirdCategory/${this.requestData.oneCategory}`, {
      method: 'GET',
    });
    this.modalData = data;
  }

  async onInit() {
    const data = await this.appState.fetch(`/${this.baseUrl}/getThirdCategory/${this.requestData.oneCategory}`, {
      method: 'GET',
    });

    this.records = data.threeCategoryDTO;
    // this.modalDataSource = { oneCategory: data.oneCategory };
  }

  async getFirstCategory() {
    const data = await this.appState.fetch(`/${this.baseUrl}/getFirstCategory`, {
      method: 'GET',
    });
    this.firstCategorys = data;
    this.updateData('requestData', 'oneCategory', data[0].id);
    this.updateData('requestData', 'oneCategoryText', data[0].name);
  }

  async updateExpandDetail(id) {
    const { spuDTOS } = await this.appState.fetch(`/three-category/${id}`, {
      method: 'GET',
    });
    this.allExpandData = {
      ...this.allExpandData,
      [id]: spuDTOS,
    };
  }

  async addThreeCategory(data) {
    // console.log(this.records, 'records')
    await this.appState.fetch(`/category-config`, {
      method: 'PUT',
      body: data,
    });
  }

  async addThreeCategorySpu(data) {
    await this.appState.fetch(`/three-category`, {
      method: 'PUT',
      body: data,
    });
  }

  async patchThreeCategorySpu(id, data) {
    await this.appState.fetch(`/three-category/${id}`, {
      method: 'PATCH',
      body: data,
    });
  }

  async deleteThreeCategory(oneCategory, threeCategoryId) {
    await this.appState.fetch(`/category-config/${oneCategory}/${threeCategoryId}`, {
      method: 'DELETE',
    });
  }

  async deleteThreeCategorySpu(threeCategoryId, spuId) {
    await this.appState.fetch(`/three-category/${threeCategoryId}/${spuId}`, {
      method: 'DELETE',
    });
  }

  async patchThreeCategory(id, data) {
    await this.appState.fetch(`/three-category/${id}`, {
      method: 'PATCH',
      body: data,
    });
  }

  async patchOneCategory(id, data) {
    await this.appState.fetch(`/category-config/${id}`, {
      method: 'PATCH',
      body: data,
    });
  }
}
