import { appState } from '@zxhj/component-library';

import SaasList from './saasList';
import Device from './device';
// import NewBasicModel from './newBasicModel';

class Store {
  appState = appState;

  saasListModel = new SaasList({ appState: this.appState });

  deviceModel = new Device({ appState: this.appState });
}

export default Store;
