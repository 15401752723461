import { action, observable, computed, makeObservable, toJS } from 'mobx';

import { leadInKeys } from '@/utils/utils';
import BasicModel from '../basicModel';

export default class settlementSaas extends BasicModel {
  constructor(props) {
    super({
      ...props,
      initRequestData: {
        pageSize: 10,
        pageNum: 1,
        type: 1, // 默认当前结算为采购结算
      },
      baseUrl: 'settlementSaas',
    });

    makeObservable(this, {});
  }

  async toPayMoment(data, id) {
    await this.appState.fetch(`/${this.baseUrl}/toPayment/${id}`, {
      method: 'PATCH',
      search: {
        totalPrice: data.amount,
      },
    });
  }

  async onInit() {
    const { records, total } = await this.appState.fetch(`/${this.baseUrl}`, {
      method: 'GET',
      search: toJS(this.requestData),
    });

    this.records = records;
    this.total = total;
  }

  async toInvoicing(data, id) {
    await this.appState.fetch(`/${this.baseUrl}/invoicing/${id}`, {
      method: 'PATCH',
      search: {
        invoicingPic: data.invoicingPic,
      },
      body: {
        recipientAddress: data.recipientAddress,
        recipientName: data.recipientName,
        recipientPhone: data.recipientPhone,
        remark: data.remark,
      },
    });
  }

  // 完成结算单
  async doneSettlement(id) {
    await this.appState.fetch(`/${this.baseUrl}/done/${id}`, {
      method: 'PATCH',
    });
  }
}
