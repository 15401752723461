import { appState } from '@zxhj/component-library';

import BudgetMatchField from './budgetMatchField';
import Unit from './unit';

class Store {
  appState = appState;

  budgetMatchFieldModel = new BudgetMatchField({ appState: this.appState });

  unitModel = new Unit({ appState: this.appState });
}

export default Store;
