import { observable, runInAction, makeObservable, toJS, action } from 'mobx';
import { peerRequestData } from '@/utils/utils';
import BasicModel from '../../basicModel';

export default class AddSold extends BasicModel {
  initRequestData = {
    pageSize: 10,
    pageNum: 1,
  };

  purchaseDTOList = []; // 子表格展示

  // allExpandData = [];
  flowUserId = '';

  purchaseQuote = []; // 报价提交参数

  constructor(props) {
    super({ ...props, baseUrl: 'zl-online-products' });
    this.initRequestData = {
      pageSize: 10,
      pageNum: 1,
      tradeType: props.tradeType,
    };
    this.requestData = {
      ...this.initRequestData,
    };
    makeObservable(this, {
      purchaseDTOList: observable,
      purchaseQuote: observable,
      spuOnline: action.bound,
      skuOnline: action.bound,
    });
  }

  async onInit() {
    const { records, total } = await this.appState.fetch(`/zl-spu`, {
      method: 'GET',
      search: peerRequestData(this.requestData),
    });

    this.records = records;
    this.total = total;
  }

  // 覆盖默认 getDetail
  async getDetail(id) {
    const data = await this.appState.fetch(`/${this.baseUrl}/prepurchase/${id}`, {
      method: 'GET',
    });
    this.modalData = data;
  }

  async updateExpandDetail(id) {
    const { skuList } = await this.appState.fetch(`/zl-spu/${id}`, {
      method: 'GET',
    });

    runInAction(() => {
      this.allExpandData[id] = skuList;
    });
  }

  async getAllExpandDetail() {
    const ids = Object.keys(this.allExpandData);
    const obj = {};
    for (let index = 0; index < ids.length; index++) {
      const id = ids[index];
      // eslint-disable-next-line no-await-in-loop
      const { purchaseDTOList } = await this.appState.fetch(`/${this.baseUrl}/prepurchase/${id}`, {
        method: 'GET',
      });
      obj[id] = purchaseDTOList;
    }
    this.allExpandData = obj;
  }

  async putOnlineProducts(data) {
    await this.appState.fetch(`/${this.baseUrl}/batchAddOnlineProduct`, {
      method: 'PUT',
      body: data,
    });
  }

  clearExpandDetail(id) {
    delete this.allExpandData[String(id)];
  }

  // 商品上下架
  async spuOnline(id, onlineStatus) {
    await this.appState.fetch(`/zl-spu/onlineSpu/${id}`, {
      method: 'POST',
      formData: { onlineStatus },
    });
  }

  // 商品规格上下架
  async skuOnline(id, onlineStatus) {
    await this.appState.fetch(`/zl-sku/onlineSku/${id}`, {
      method: 'POST',
      formData: { onlineStatus },
    });
  }
}
