import { action, observable, computed, makeObservable, toJS } from 'mobx';
import { peerRequestData } from '@/utils/utils';

export default class BasicModel {
  initRequestData = {
    pageSize: 10,
    pageNum: 1,
  };

  defaultStatic = {};

  records = [];

  total = 0;

  // 展开列表
  expandedRowKeys = [];

  allExpandData = {};

  modalVisible = false;

  modalMode = 'detail'; // detail  onInitRequestData详情 || add 新增 || edit 编辑

  editId;

  modalData = {};

  constructor(params = {}) {
    // 子类任何属性都将实例化
    Object.keys(params).length > 0 &&
      Object.keys(params).forEach((key) => {
        this[key] = params[key];
      });
    const { initRequestData } = params;
    this.requestData = initRequestData || { ...this.initRequestData };
    // this.appState = appState;
    makeObservable(this, {
      // 表格渲染相关属性
      defaultStatic: observable,
      total: observable,
      requestData: observable,
      records: observable,
      // 子表格人相关属性
      expandedRowKeys: observable,
      allExpandData: observable,
      // 弹窗相关属性
      modalVisible: observable,
      modalMode: observable,
      editId: observable,
      modalData: observable,
      // 调用方法
      initData: action.bound,
      updateData: action.bound,
      updateList: action.bound,
      // 子表格快捷方法
      updateExpandDetail: action.bound,
      clearExpandDetail: action.bound,

      initStatic: action.bound,
      onInit: action.bound,
      put: action.bound,
      patch: action.bound,
      delete: action.bound,
      getDetail: action.bound,
      openModal: action.bound,
      closeModal: action.bound,
    });
  }

  clearExpandDetail(id) {
    delete this.allExpandData[String(id)];
  }

  async updateExpandDetail(id) {
    const data = await this.appState.fetch(`/${this.baseUrl}/${id}`, {
      method: 'GET',
    });
    this.allExpandData = {
      ...this.allExpandData,
      [id]: data,
    };
  }

  openModal(modalMode) {
    this.modalVisible = true;
    this.modalMode = modalMode;
  }

  closeModal() {
    this.modalVisible = false;
    this.modalData = {};
  }

  updateData(dataName, key, value) {
    this[dataName] = {
      ...this[dataName],
      [key]: value,
    };
  }

  initData(dataName, init) {
    this[dataName] = init;
  }

  updateList(listName, index, item) {
    if (item) {
      this[listName].splice(index, 1, item);
    } else {
      this[listName].splice(index, 1);
    }
  }

  async onInit() {
    // const obj = {};
    // for (const key in this.requestData) {
    //   if (Object.hasOwnProperty.call(this.requestData, key)) {
    //     const value = this.requestData[key];
    //     obj[key] = typeof value === "object" ? value.value : value
    //   }
    // }
    const { records, total } = await this.appState.fetch(`/${this.baseUrl}`, {
      method: 'GET',
      search: peerRequestData(this.requestData),
    });

    this.records = records;
    this.total = total;
  }

  async getDetail(id) {
    const data = await this.appState.fetch(`/${this.baseUrl}/${id}`, {
      method: 'GET',
    });

    this.modalData = data;
  }

  async put(data) {
    await this.appState.fetch(`/${this.baseUrl}/`, {
      method: 'PUT',
      body: data,
    });
    this.modalData = data;
  }

  async patch(data, id) {
    await this.appState.fetch(`/${this.baseUrl}/${id}`, {
      method: 'PATCH',
      body: data,
    });
  }

  async delete(id) {
    await this.appState.fetch(`/${this.baseUrl}/${id}`, {
      method: 'DELETE',
    });
  }

  async initStatic() {
    const data = await this.appState.fetch(`/${this.baseUrl}/init`, {
      method: 'GET',
    });
    this.defaultStatic = data;
  }
}
