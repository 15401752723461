import BasicModel from '../../basicModel';

export default class RentBrand extends BasicModel {
  constructor(props) {
    super({ ...props, baseUrl: 'brand/zl' });
  }

  async getDetail(id) {
    const data = await this.appState.fetch(`/brand/${id}`, {
      method: 'GET',
    });

    this.modalData = data;
  }

  async patch(data, id) {
    await this.appState.fetch(`/brand/${id}`, {
      method: 'PATCH',
      body: data,
    });
  }

  async delete(id) {
    await this.appState.fetch(`/brand/${id}`, {
      method: 'DELETE',
    });
  }
}
