import { action, observable, computed, makeObservable, toJS } from 'mobx';

import { leadInKeys } from '@/utils/utils';
import { shipments } from '../schema/purchase';
import BasicModel from '../basicModel';

export default class StockOperationDetails extends BasicModel {
  constructor(props) {
    super({ ...props, baseUrl: 'stock-operation-details' });
    makeObservable(this, {});
  }
}
