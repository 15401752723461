import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

const BlankLayout = ({ children }) => {
  const history = useHistory();

  // eslint-disable-next-line react/jsx-filename-extension
  return <>{children}</>;
};

export default BlankLayout;
